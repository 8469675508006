<template>
    <section class="agent" id="agent">
        <div class="bac-girl" :style="{background: 'url(' + img + ') no-repeat left bottom'}"/>
        <b-row>
            <b-col md="12" lg="7" offset-lg="5">
                <div class="content">
                    <h2 class="title">Подключиться — <span class="bordered-text">просто</span></h2>
                    <div class="agent__steps">
                        <div v-for="(s,indx) in steps" :key="indx" class="step-item d-flex flex-column flex-md-row align-items-start align-items-md-end">
                            <div class="step">0{{ indx + 1 }}</div>
                            <div class="text">{{ s.title }}</div>
                        </div>
                    </div>
                    <div class="btns-container align-items-center bottom-block">
                        <div class="btns-container__btn-wrapper">
                            <a class="btn btn-green" :href="agentUrl">Стать агентом</a>
                        </div>
                        <div class="btns-container__btn-wrapper">
                            <a class="btn btn-green-secondary" :href="clientUrl">Стать клиентом</a>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import Img from '@/assets/agent.png'
export default {
    name: "BecomeAnAgent",
    data() {
        return {
            steps: [
                {
                    id: 1, title: 'Зарегистрируйтесь'
                },
                {
                    id: 2, title: 'Примите условия оферты'
                },
                {
                    id: 3, title: 'Получите доступ в личный кабинет'
                }
            ],
            agentUrl: process.env.VUE_APP_NEW_LK_REGISTER,
            clientUrl: process.env.VUE_APP_BASE_CLIENT_URL_LK,
            img: Img
        }
    }
}
</script>

<style lang="scss">
.agent {
    background: linear-gradient(255.58deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.02));
    border-radius: 20px;
    margin-top: 120px;
    position: relative;
    .bac-girl {
        position: absolute;
        top: 100px;
        bottom: 0;
        left: 0;
        right: 90px;
        z-index: -1;
    }

    .content {
        padding: 120px 80px 120px 0;

        .agent__steps {
            margin-top: 47px;

            .step-item {
                padding: 20px 0;
                border-bottom: 1px solid #ddd;
            }

            .step {
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                color: #AAAAAA;
                margin-right: 20px;
                width: 70px;
            }

            .text {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                padding-bottom: 5px;
            }
        }

        .bottom-block {
            margin-top: 80px;
            .text {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                width: 150px;
            }
        }

        .btn.btn-green {
            color: #fff;
            //margin-right: 1.875rem;
        }
    }
}
@media (max-width: 1280px) {
    .agent {
        .content {
            padding: 120px 80px;

            .bottom-block {
                .btn-green,
                .btn-green-secondary {
                    width: 100%;
                }

                .text {
                    width: 100%;
                    padding: 20px 0;
                }
            }
        }
    }
}
@media (max-width: 1080px) {
    .agent {
        .col-md-12 {
            flex: 0 0 auto;
            width: 100%;
        }
        .offset-lg-5 {
            margin-left: 0;
        }
    }
}
@media (max-width: 767px) {
    .agent {
        margin-top: 60px;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        background: transparent;
        .content {
            padding: 0 0 60px 0;
            .bottom-block {
                margin-top: 50px;
            }

            .agent__steps .step {
                margin-bottom: 10px;
            }

            // .btn.btn-green {
            //     margin-right: 0;
            //     margin-bottom: 20px;
            // }
            .text {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.btns-container{
    &__btn-wrapper {
        width: 100%;
    }

    @media (min-width: 1281px) {
        &__btn-wrapper {
            width: auto;
        }
    }
}
</style>